body {
  padding: var(--app-offset);
  margin: 0 auto;
}

body,
textarea,
button,
*::placeholder {
  font-family: "Muller", apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.02em;
}

b, strong {
  font-weight: 600;
}

em {
  font-style: italic;
}
