.stage-tracker {
  display: flex;
  justify-content: space-between;
}

.stage-progress {
  margin-top: 65px;  
  position: relative;
  width: 1170px;  
  display: flex;
  justify-content: space-between;
  bottom: 20px;
  height: 4px;
  background-color: black;
}

.stage {
  position: relative;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: lightgray;  
}

.stage-circle {
  position: absolute;
  z-index: 1;  
  left: 50%;
  top: -7.5px; /* половина высоты круга */
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border: 2px solid black;
  border-radius: 50%;
  font-size: 12px;
  background-color: black;
}

.stage-label {
  position: absolute;
  top: -35px;
  right: -40px;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.5;
  width: 150px
}