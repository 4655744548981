.common-rc-notification.rc-notification {
  position: fixed;
  bottom: -2px !important;
  top: unset !important;
  left: 0 !important;
  margin-left: var(--app-offset);
  margin-bottom: var(--app-offset);
}

.common-rc-notification.rc-notification-notice {
  position: relative;
  display: block;
  width: auto;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  border: none;
  box-shadow: none;
  background: none;
}

.common-rc-notification.rc-notification-notice:last-of-type {
  margin-bottom: 0;
}
