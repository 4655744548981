:root {
  /* DEPRECATED START */
  /* --main: #e9041e; */
  --main: #ffdd2d;

  --secondary: #282423;

  --danger: #e9041e;
  --link: #807d7d;

  --bg-color: #f2f2f2;

  --font-size-large: 40px;
  --font-size-normal: 14px;
/* DEPRECATED END*/

  --brand-color-primary: #ffdd2d;
  --brand-color-black: #282423;
  --brand-color-gray: #c4c4c4;
  --brand-color-gray-dark: #807d7d;
  --brand-color-gray-light: #f2f2f2;
  --brand-color-white: #ffffff;

  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 24px;
  --font-size-l: 40px;
  --font-size-xl: 60px;

  --template-sidebar-left-width: 128px;
  --template-sidebar-right-width: 176px;
  --template-content-width: 100%;

  --input-width: 495px;
  --app-offset: 60px;
}
