@font-face {
  font-family: 'Muller';
  src: url("../fonts/MullerRegular.eot");
  src: url("../fonts/MullerRegular.woff2") format('woff2'), url("../fonts/MullerRegular.woff") format('woff'), url("../fonts/MullerRegular.ttf") format('truetype'), url("../fonts/MullerRegular.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: 'Muller';
  src: url("../fonts/MullerMedium.eot");
  src: url("../fonts/MullerMedium.woff2") format('woff2'), url("../fonts/MullerMedium.woff") format('woff'), url("../fonts/MullerMedium.ttf") format('truetype'), url("../fonts/MullerMedium.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: 'Muller';
  src: url("../fonts/MullerBold.eot");
  src: url("../fonts/MullerBold.woff2") format('woff2'), url("../fonts/MullerBold.woff") format('woff'), url("../fonts/MullerBold.ttf") format('truetype'), url("../fonts/MullerBold.otf") format("opentype");
  font-weight: bold;
}
